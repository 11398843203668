/**
 * @module stateManager
 */
import React from "react";
import * as _ from "lodash";

import storage from "@/utils/storage";
import { filterArray } from "@/utils/array/filterArray";

/**
 * Changes current state of the manager
 * @private
 * @function __set_state
 * @param {Object|Promise} value - Object to be merged into the new state or a Promise that returns a object
 * @param {Function} [callback] - Callback function
 */
function __set_state(value, callback) {
  if (typeof value === "function") {
    value = value(this.state);
  }
  Promise.resolve(value).then(async resp => {
    this.state = { ...this.state, ...resp };
    this.listeners.forEach(listener => {
      listener(this.state);
    });
    if (this.opts && this.opts.local) {
      let stateKeys = Object.keys(this.state);
      if (Array.isArray(this.opts.blacklist)) {
        this.opts.blacklist.forEach(key => {
          stateKeys = filterArray(stateKeys, key, "indexOf", true);
        });
      }
      let state = {};
      stateKeys.map(
        k => (state = { ...state, [k]: _.cloneDeep(this.state[k]) })
      );
      await storage.set(this.opts.local, true)(state);
    }
    if (callback) {
      callback(this.state);
    }
  });
}

/**
 * Use custom hook emulation effect for state setting binding/unbinding
 * @private
 * @function __use_custom
 * @returns {[Object, Function]} Current state and function for state updating
 */
function __use_custom() {
  const [, newListener] = React.useState();
  React.useEffect(() => {
    this.listeners.push(newListener);
    return () => {
      this.listeners = this.listeners.filter(
        listener => listener !== newListener
      );
    };
  }, []);
  return [this.state, this.setState];
}

/**
 * Exports a custom local state manager function to be used in place of any Redux like logic
 * @function stateManager
 * @param {Object} initialState - The initial state of the manager
 * @param {Object} opts? - State manager opts
 * @returns {[Object, Function]} Current state and function for state updating
 */
export default (initialState, opts = {}) => {
  const store = {
    state: initialState,
    listeners: [],
    opts
  };
  store.setState = __set_state.bind(store);

  if (opts && opts.local) {
    storage
      .get(opts.local, true)()
      .then(localState => {
        if (localState !== null) {
          store.setState(localState);
        }
      });
  }
  return __use_custom.bind(store);
};
