import { createGlobalStyle } from "styled-components";

const styles = createGlobalStyle`
  @keyframes zoomIn {
    0% {
      opacity: 0;
      width: 0px;
      height: 0px;
    }
    40% {
      opacity: 1;
      width: 160px;
      height: 160px;
      top: calc(50vh - 80px);
      left: calc(50vw - 80px);
    }
    50% {
      opacity: 1;
      width: 150px;
      height: 150px;
      top: calc(50vh - 75px);
      left: calc(50vw - 75px);
    }
    100% {
      opacity: 1;
      width: 150px;
      height: 150px;
      top: calc(50vh - 75px);
      left: calc(50vw - 75px);
    }
  }

  @keyframes zoomOut {
    0% {
      width: 150px;
      height: 150px;
      top: calc(50vh - 75px);
      left: calc(50vw - 75px);
    }
    100% {
      top: 32px;
      left: 24px;
      width: 115px;
      height: 32px;
    }
  }

  @keyframes upIcon {
    0% {
      top: 32px;
      left: 24px;
      width: 115px;
      height: 32px;
      opacity: 1;
    }
    100% {
      top: -470px;
      left: 24px;
      width: 115px;
      height: 32px;
      opacity: 1;
    }
  }

  @keyframes downIcon {
    0% {
      top: -470px;
      left: 24px;
      width: 115px;
      height: 32px;
      opacity: 1;
    }
    100% {
      top: 32px;
      left: 24px;
      width: 115px;
      height: 32px;
      opacity: 1;
    }
  }
  
  @keyframes upLinguagePanel {
    0% {
      bottom: -530px;
    }
    100% {
      bottom: 0px;
    }
  }
  
  @keyframes downLinguagePanel {
    0% {
      bottom: 0px;
    }
    100% {
      bottom: -530px;
    }
  }

  @keyframes resizeLogin {
    0% {
      height: 100vh;
    }
    1% {
      border-radius: 32px 32px 0 0;
    }
    100% {
      border-radius: 32px 32px 0 0;
      height: 530px;
    }
  }

  @keyframes fadeOutLogin {
    0% {
      border-radius: 32px 32px 0 0;
      height: 530px;
      bottom: 0;
      opacity: 1;
    }
    100% {
      border-radius: 32px 32px 0 0;
      height: 530px;
      bottom: -400px;
      opacity: 0;
    }
  }

  @keyframes upForm {
    0% {
      opacity: 1;
      bottom: -530px;
    }
    100% {
      opacity: 1;
      bottom: -0px;
    }
  }

  @keyframes upFormForLinguagePanel {
    0% {
      opacity: 1;
      bottom: 0px;
    }
    100% {
      opacity: 1;
      bottom: 500px;
    }
  }

  @keyframes downForm {
    0% {
      opacity: 1;
      bottom: 500px;
    }
    100% {
      opacity: 1;
      bottom: 0px;
    }
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes slideInRight {
    0% {
      transform: translate3d(100%, 0, 0);
      visibility: visible
    }
    to {
      transform: translateZ(0)
    }
  }

  @keyframes slideOutRight {
    0% {
      transform: translateZ(0);
    }
    to {
      visibility: hidden;
      transform: translate3d(100%, 0, 0);
    }
  }
`;

export default styles;
