import * as _ from "lodash";

import stateManager from "@/utils/stateManager";
import constants from "@/config/constants";

import tinyCarLight from "@/assets/emoji/tiny-police-car-light.png";
import tinyAirplane from "@/assets/emoji/tiny-airplane.png";
import tinyStudent from "@/assets/emoji/tiny-woman-student-medium-skin-tone.png";

import airplane from "@/assets/emoji/list/airplane.png";
import bank from "@/assets/emoji/list/bank.png";
import boat from "@/assets/emoji/list/boat.png";
import camera from "@/assets/emoji/list/camera.png";
import car from "@/assets/emoji/list/car.png";
import graphic from "@/assets/emoji/list/graphic.png";
import hospital from "@/assets/emoji/list/hospital.png";
import house from "@/assets/emoji/list/house.png";
import italy from "@/assets/emoji/list/italy.png";
import notebook from "@/assets/emoji/list/notebook.png";
import usa from "@/assets/emoji/list/usa.png";
import woman from "@/assets/emoji/list/woman.png";

const {
  ICONS,
  LANGUAGE_SETTINGS,
  EMERGENCY,
  MEDIUM_TERMS,
  LONG_TERMS,
  MONTHLY_EARN,
  MONTHLY_SPEND,
  SAVING_PERIOD,
  SUGGESTION,
  CHANGE_SUGGESTION,
  MONEY_DISTRIBUTION
} = constants.ENTITY;

export const struct = {
  name: "",
  value: 0
};

export const structDistribution = (
  id,
  name_id,
  tx,
  iconBackground,
  barBackground,
  border,
  shadow,
  icon
) => ({
  id,
  name_id,
  tx: tx || 1,
  iconBackground: iconBackground || "#ffe6f7",
  barBackground: barBackground || "#ffb3e6",
  border: border || "#ffb3e6",
  shadow: shadow || "rgba(255, 51, 187, 0.1)",
  icon: icon || tinyCarLight
});

export const initialState = {
  isLoading: false,
  loadingMessage: "",
  backCount: -1,
  [`${LANGUAGE_SETTINGS}`]: constants.LANGUAGE_ID_DEFAULT,
  [`${EMERGENCY}`]: 0,
  [`${MEDIUM_TERMS}`]: [],
  [`${LONG_TERMS}`]: [],
  [`${MONTHLY_EARN}`]: 0,
  [`${MONTHLY_SPEND}`]: 0,
  [`${SAVING_PERIOD}`]: -1,
  [`${SUGGESTION}`]: -1,
  [`${CHANGE_SUGGESTION}`]: "",
  [`${MONEY_DISTRIBUTION}`]: [
    _.cloneDeep(
      structDistribution(
        1,
        "goal.emergency",
        0.8,
        "#ffe6f7",
        "#ffb3e6",
        "#ffb3e6",
        "rgba(255, 51, 187, 0.1)",
        tinyCarLight
      )
    ),
    _.cloneDeep(
      structDistribution(
        2,
        "goal.medium-term",
        0.1,
        "#e6f1ff",
        "#b3d6ff",
        "#b3d6ff",
        "rgba(51, 153, 255, 0.1)",
        tinyAirplane
      )
    ),
    _.cloneDeep(
      structDistribution(
        3,
        "goal.long-term",
        0.1,
        "#fff7e6",
        "#ffe6b3",
        "#ffe4b3",
        "rgba(255, 187, 51, 0.1)",
        tinyStudent
      )
    )
  ],
  [`${ICONS}`]: [
    bank,
    woman,
    boat,
    camera,
    car,
    airplane,
    graphic,
    hospital,
    house,
    italy,
    notebook,
    usa
  ]
};

export default stateManager(initialState, {
  local: `${constants.STORAGE_PREFIX_KEY}root`,
  blacklist: ["isLoading", "loadingMessage", "backCount"]
});
