import constants from "@/config/constants";

export const routes = async () =>
  await Promise.all([
    {
      name: "WELCOME",
      path: constants.ROUTES.WELCOME,
      loader: await import("../pages/Welcome")
    },
    {
      name: "EMERGENCY",
      path: constants.ROUTES.EMERGENCY,
      loader: await import("../pages/Emergency")
    },
    {
      name: "MEDIUM-TERM",
      path: constants.ROUTES.MEDIUM_TERM,
      loader: await import("../pages/MediumTerm")
    },
    {
      name: "MEDIUM-TERMS-LIST",
      path: constants.ROUTES.MEDIUM_TERMS_LIST,
      loader: await import("../pages/MediumTermsList")
    },
    {
      name: "LONG-TERM",
      path: constants.ROUTES.LONG_TERM,
      loader: await import("../pages/LongTerm")
    },
    {
      name: "LONG-TERMS-LIST",
      path: constants.ROUTES.LONG_TERMS_LIST,
      loader: await import("../pages/LongTermsList")
    },
    {
      name: "EDIT-ICON",
      path: constants.ROUTES.EDIT_ICON,
      loader: await import("../pages/EditIcon")
    },
    {
      name: "ALL-DONE",
      path: constants.ROUTES.ALL_DONE,
      loader: await import("../pages/AllDone")
    },
    {
      name: "CONGRATS",
      path: constants.ROUTES.CONGRATS,
      loader: await import("../pages/Congrats")
    },
    {
      name: "SAVING-PERIOD",
      path: constants.ROUTES.SAVING_PERIOD,
      loader: await import("../pages/SavingPeriod")
    },
    {
      name: "GREAT",
      path: constants.ROUTES.GREAT,
      loader: await import("../pages/Great")
    },
    {
      name: "HOW-MUCH-SAVE",
      path: constants.ROUTES.HOW_MUCH_SAVE,
      loader: await import("../pages/HowMuchSave")
    },
    {
      name: "INTEND-SAVING",
      path: constants.ROUTES.INTEND_SAVING,
      loader: await import("../pages/IntendSaving")
    },
    {
      name: "TOTAL-BALANCE",
      path: constants.ROUTES.TOTAL_BALANCE,
      loader: await import("../pages/TotalBalance")
    },
    {
      name: "MONEY-DISTRIBUTION",
      path: constants.ROUTES.MONEY_DISTRIBUTION,
      loader: await import("../pages/MoneyDistribution")
    }
  ]);
