import StorageDriver from "./StorageDriver";

export default function set(key, stringify) {
  return async value => {
    try {
      let newValue = value;
      if (stringify) {
        newValue = JSON.stringify(newValue);
      }
      const res = await StorageDriver.setItem(key, newValue).catch(() => false);
      return typeof res === "boolean" ? res : res === null || res === undefined;
    } catch (e) {
      // continue
    }
    return false;
  };
}
