import StorageDriver from "./StorageDriver";

export default function get(key, parse) {
  return async () => {
    let item = null;
    try {
      item = await StorageDriver.getItem(key);
      if (parse) {
        item = JSON.parse(item);
      }
    } catch (e) {
      // continue
    }
    return item;
  };
}
