// Node modules import
import React, { useEffect, useState } from "react";
import Router from "@/router";
import { IntlProvider } from "react-intl";

// Project imports
import constants from "@/config/constants";
import sharedState from "@/sharedState";

const App = () => {
  // noinspection JSValidateTypes
  const [globalState] = sharedState();

  const defaultLanguage = constants.LANGUAGE_OPTIONS.find(
    ({ language_id }) => language_id === constants.LANGUAGE_ID_DEFAULT
  );
  const [language, setLanguage] = useState(defaultLanguage);

  useEffect(() => {
    const newLanguage = constants.LANGUAGE_OPTIONS.find(
      ({ language_id }) =>
        language_id === globalState[constants.ENTITY.LANGUAGE_SETTINGS]
    );
    setLanguage(newLanguage);
  }, [globalState[constants.ENTITY.LANGUAGE_SETTINGS]]);

  return (
    <IntlProvider
      locale={language.locale}
      messages={constants.MESSAGES_LANGUAGES[language.locale]}
    >
      <Router />
    </IntlProvider>
  );
};

export default App;
