import React, { useState, useEffect } from "react";
import { Switch, Route } from "react-router-dom";

import GlobalStyles from "@/config/styles";
import sharedState from "@/sharedState";

const Router = () => {
  // noinspection JSValidateTypes
  const useGlobalState = sharedState();
  const [nav, setNav] = useState(null);

  useEffect(() => {
    // noinspection JSIgnoredPromiseFromCall
    updateNav();
  }, []);

  const updateNav = async () => {
    let routesList = null;
    routesList = require("./routes").routes;

    const NAVIGATIONS = await routesList();
    setNav(NAVIGATIONS);
  };

  return (
    <>
      <GlobalStyles />
      <Switch>
        {nav &&
          nav.map(({ name, path, loader: { default: Component } }) => (
            <Route
              key={name}
              exact
              path={path}
              render={props => (
                <Component {...props} useGlobalState={useGlobalState} />
              )}
            />
          ))}
      </Switch>
    </>
  );
};

export default Router;
