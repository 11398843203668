import messages_en from "@/translations/en.json";
import messages_pt from "@/translations/pt.json";
import messages_es from "@/translations/es.json";

export default {
  TOKEN_KEY: "sessionToken",
  STORAGE_PREFIX_KEY: "lamedichi-goals-web-",
  ROUTES: {
    WELCOME: "/:userId/:name/:savings/:language/:action",
    EMERGENCY: "/emergency",
    EDIT_ICON: "/edit-icon",
    NEW_MEDIUM_TERM: "/medium-term/-1",
    MEDIUM_TERM: "/medium-term/:index",
    MEDIUM_TERMS_LIST: "/medium-terms-list",
    NEW_LONG_TERM: "/long-term/-1",
    LONG_TERM: "/long-term/:index",
    LONG_TERMS_LIST: "/long-terms-list",
    ALL_DONE: "/all-done",
    CONGRATS: "/congrats",
    SAVING_PERIOD: "/saving-period",
    GREAT: "/great",
    HOW_MUCH_SAVE: "/how-much-save",
    INTEND_SAVING: "/intend-saving",
    TOTAL_BALANCE: "/total-balance",
    MONEY_DISTRIBUTION: "/money-distribution"
  },
  ENTITY: {
    NAME: "name",
    ICONS: "icons",
    SAVINGS: "savings",
    USER_ID: "user-id",
    EMERGENCY: "emergency",
    LONG_TERMS: "long-terms",
    MEDIUM_TERMS: "medium-terms",
    SUGGESTION: "save-suggestion",
    SAVING_PERIOD: "saving-period",
    LANGUAGE_SETTINGS: "language-settings",
    CHANGE_SUGGESTION: "change-suggestion",
    MONEY_DISTRIBUTION: "money-distribution"
  },
  MESSAGES_LANGUAGES: {
    en: messages_en,
    pt: messages_pt,
    es: messages_es
  },
  LANGUAGE_ID_DEFAULT: 1,
  LANGUAGE_OPTIONS: [
    {
      language_id: 1,
      message_id: "spanish",
      locale: "es"
    },
    {
      language_id: 2,
      message_id: "english",
      locale: "en"
    },
    {
      language_id: 3,
      message_id: "portuguese",
      locale: "pt"
    }
  ],
  PERIOD_OPTIONS: [
    {
      option_id: 1,
      value: 30,
      tag: "every_day",
      message_id: "savingPeriod.index.day",
      frequency_id: "great.index.frequency-day",
      label_id: "great.index.day"
    },
    {
      option_id: 2,
      value: 4,
      tag: "every_week",
      message_id: "savingPeriod.index.week",
      frequency_id: "great.index.frequency-week",
      label_id: "great.index.week"
    },
    {
      option_id: 3,
      value: 2,
      tag: "every_2_weeks",
      message_id: "savingPeriod.index.twice-month",
      frequency_id: "great.index.frequency-twice-month",
      label_id: "great.index.twice-month"
    },
    {
      option_id: 4,
      value: 1,
      tag: "every_month",
      message_id: "savingPeriod.index.once-month",
      frequency_id: "great.index.frequency-once-month",
      label_id: "great.index.once-month"
    }
  ]
};
