import StorageDriver from "./StorageDriver";

export default function remove(key) {
  return async () => {
    try {
      const res = await StorageDriver.removeItem(key).catch(() => false);
      return typeof res === "boolean" ? res : res === null || res === undefined;
    } catch (e) {
      // continue
    }
    return false;
  };
}
